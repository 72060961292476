import React, {Component} from 'react';

class HeadBanner extends Component {
    render() {
        return (
            <h1>About</h1>
        )
    };
}

export default HeadBanner;